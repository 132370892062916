import React, { Component } from 'react';
import styled from 'styled-components';
import { withPage } from '../../Page';
import ActionCreator from '../../ActionCreator';
import { connect } from 'react-redux';

class CategoryPage extends Component {
  render() {
    let { categoryId } = this.props;
    return (
      <Wrapper>
        <div style={{ marginTop: 100 }}>
          this is category page!! - {categoryId}
        </div>
      </Wrapper>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      categoryId: ownProps.pageContext.categoryId,
    }),
    ActionCreator
  )(CategoryPage)
);

let Wrapper = styled.div`
  padding: 20px;
`;
